import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  // url: string = "https://bwsoft.in/api/";
  // url: string = "http://127.0.0.1:8000/";
  // url: string = "https://uniquesmart.bwsoft.in/api/";
  url: string = "https://merakie.bwsoft.in/api/";
  constructor(private httpClient: HttpClient) {}
  login(data: { email: string; password: string }) {
    data.email = data.email.trim();
    return this.httpClient.post(this.url + "admin/login/", data);
  }
  /* Categories */
  getCategories() {
    return this.httpClient.get(this.url + "categorylist/");
  }
  addCategory(data: any) {
    return this.httpClient.post(this.url + "category/create/", data);
  }
  getCategoryById(id: number) {
    return this.httpClient.get(this.url + "getcategory/" + id);
  }
  updateCategory(data: any, id: number) {
    return this.httpClient.put(this.url + "category/update/" + id + "/", data);
  }
  deleteCategory(id: number) {
    return this.httpClient.delete(this.url + "category/delete/" + id);
  }
  /* Categories end */

  /* Users */
  getUsers() {
    return this.httpClient.get(this.url + "users/");
  }
  addUser(data: any) {
    return this.httpClient.post(this.url + "user/add/", data);
  }
  getUserById(id: number) {
    return this.httpClient.get(this.url + "getuser/" + id + "/");
  }
  updateUser(data: any, id: number) {
    return this.httpClient.put(this.url + "user/update/" + id + "/", data);
  }
  deleteUser(id: number) {
    return this.httpClient.delete(this.url + "user/delete/" + id);
  }
  /* Users end */

  /* Outlets */
  getOutlets() {
    return this.httpClient.get(this.url + "outletlist/");
  }
  addOutlet(data: any) {
    return this.httpClient.post(this.url + "outlet/create/", data);
  }
  getOutletById(id: number) {
    return this.httpClient.get(this.url + "getoutlet/" + id);
  }
  updateOutlet(data: any, id: number) {
    return this.httpClient.put(this.url + "outlet/update/" + id + "/", data);
  }
  deleteOutlet(id: number) {
    return this.httpClient.delete(this.url + "outlet/delete/" + id);
  }
  /* Outlets end */
  /* Settings api */
  getSystemDetails() {
    return this.httpClient.get(this.url + "settings/");
  }
  getSettings() {
    return this.httpClient.get(this.url + "settinglist/");
  }
  updateSetting(data: any, id: number) {
    return this.httpClient.put(this.url + "setting/update/" + id + "/", data);
  }
  /* Settings end */
  /* Payment methods */
  getPayments() {
    return this.httpClient.get(this.url + "paymentlist/");
  }
  addPayment(data: any) {
    return this.httpClient.post(this.url + "payment/create/", data);
  }
  getPaymentById(id: number) {
    return this.httpClient.get(this.url + "getpayment/" + id);
  }
  updatePayment(data: any, id: number) {
    return this.httpClient.put(this.url + "payment/update/" + id + "/", data);
  }
  deletePayment(id: number) {
    return this.httpClient.delete(this.url + "payment/delete/" + id);
  }
  /* Payment methods end */

  /* Supplier methods */
  getSuppliers() {
    return this.httpClient.get(this.url + "supplierlist/");
  }
  addSupplier(data: any) {
    return this.httpClient.post(this.url + "supplier/create/", data);
  }
  getSupplierById(id: number) {
    return this.httpClient.get(this.url + "getsupplier/" + id);
  }
  updateSupplier(data: any, id: number) {
    return this.httpClient.put(this.url + "supplier/update/" + id + "/", data);
  }
  deleteSupplier(id: number) {
    return this.httpClient.delete(this.url + "supplier/delete/" + id);
  }
  /* Supplier methods end */

  /* Product methods */
  getProducts() {
    return this.httpClient.get(this.url + "productlist/");
  }
  addProduct(data: any) {
    return this.httpClient.post(this.url + "product/create/", data);
  }
  getProductById(id: number) {
    return this.httpClient.get(this.url + "getproduct/" + id);
  }
  updateProduct(data: any, id: number) {
    return this.httpClient.put(this.url + "product/update/" + id + "/", data);
  }
  deleteProduct(id: number) {
    return this.httpClient.delete(this.url + "product/delete/" + id);
  }
  /* Product methods end */

  /* Customer methods */
  getCustomers() {
    return this.httpClient.get(this.url + "customerlist/");
  }
  addCustomer(data: any) {
    return this.httpClient.post(this.url + "customer/create/", data);
  }
  getCustomerById(id: number) {
    return this.httpClient.get(this.url + "getcustomer/" + id);
  }
  updateCustomer(data: any, id: number) {
    return this.httpClient.put(this.url + "customer/update/" + id + "/", data);
  }
  deleteCustomer(id: number) {
    return this.httpClient.delete(this.url + "customer/delete/" + id);
  }
  /* Customer methods end */

  /* Inventory methods */
  getInventories() {
    return this.httpClient.get(this.url + "inventorylist/");
  }
  addInventory(data: any) {
    return this.httpClient.post(this.url + "inventory/create/", data);
  }
  getInventoryById(id: number) {
    return this.httpClient.get(this.url + "getinventory/" + id);
  }
  updateInventory(data: any, id: number) {
    return this.httpClient.put(this.url + "inventory/update/" + id + "/", data);
  }
  deleteInventory(id: number) {
    return this.httpClient.delete(this.url + "inventory/delete/" + id);
  }
  getInventoryByProductId(id: number) {
    return this.httpClient.get(this.url + "getInventoryByProductId/" + id);
  }
  updateBulkInventory(data) {
    return this.httpClient.post(this.url + "bulkupdateinventory/", data);
  }
  /* Inventory methods end */

  // Order create
  getProductByCode(code: string) {
    return this.httpClient.get(this.url + "productlist/?code=" + code);
  }
  getProductByName(name: string) {
    return this.httpClient.get(this.url + "productlist/?name=" + name);
  }
  createOrder(data) {
    return this.httpClient.post(this.url + "create_order/", data);
  }
  getOrderById(id: number) {
    return this.httpClient.get(this.url + "getOrderByid/" + id);
  }
  getOrderByoutletId(id: number) {
    return this.httpClient.get(this.url + "getOrderByoutlet/" + id);
  }
  getOrderBycustomerId(id: number) {
    return this.httpClient.get(this.url + "getOrderBycustomer/" + id);
  }
  getAllOrders() {
    return this.httpClient.get(this.url + "getAllOrders/");
  }
  send_mail(id) {
    return this.httpClient.get(this.url + `send_mail/${id}/`);
  }
  // Order create

  // Order hold
  create_suspend(data) {
    return this.httpClient.post(this.url + "create_suspend/", data);
  }
  getsuspendById(id: number) {
    return this.httpClient.get(this.url + "getsuspendById/" + id);
  }
  getsuspendByoutlet(id: number) {
    return this.httpClient.get(this.url + "getsuspendByoutlet/" + id);
  }
  updateSuspendOrder(data: any, id: number) {
    return this.httpClient.put(this.url + "suspend/update/" + id + "/", data);
  }
  // Order hold

  /* Return order & update order status to return */
  createReturnOrder(data: any) {
    console.log(data, "data from api service");
    return this.httpClient.post(this.url + "create_returnorder/", data);
  }
  getReturnOrders() {
    return this.httpClient.get(this.url + "getReturnOrders");
  }
  getReturnOrderByid(id: number) {
    return this.httpClient.get(this.url + "getReturnOrderByid/" + id);
  }
  getReturnOrderByoutlet(id: number) {
    return this.httpClient.get(this.url + "getReturnOrderByoutlet/" + id);
  }
  getReturnOrderBycustomer(id: number) {
    return this.httpClient.get(this.url + "getReturnOrderBycustomer/" + id);
  }
  updateOrderStatus(id: number) {}
  //
  getDashboardchart() {
    return this.httpClient.get(this.url + "salesDataView/");
  }

  // Employee
  getEmployees() {
    return this.httpClient.get(this.url + "employelist/");
  }
  addEmployee(data) {
    return this.httpClient.post(this.url + "employe/create/", data);
  }
  getEmployeeById(id: number) {
    return this.httpClient.get(this.url + "getemploye/" + id);
  }
  updateEmployee(data, id) {
    return this.httpClient.put(this.url + "employe/update/" + id + "/", data);
  }
  deleteEmployee(id: number) {
    return this.httpClient.delete(this.url + "employe/delete/" + id);
  }
  // Employee attendance
  getEmployeesAttendance() {
    return this.httpClient.get(this.url + "employe/attendancelist/");
  }
  addEmployeeAttendance(data) {
    return this.httpClient.post(this.url + "employe/attendance/create/", data);
  }
  getEmployeeAttendanceById(id: number) {
    return this.httpClient.get(this.url + "getemploye/attendance/" + id + "/");
  }
  updateEmployeeAttendance(data, id) {
    return this.httpClient.put(this.url + "employe/attendance/update/" + id + "/", data);
  }
  deleteEmployeeAttendance(id: number) {
    return this.httpClient.delete(this.url + "employe/attendance/delete/" + id);
  }
  getEmployeeAttendanceByEmpId(empId: number) {
    return this.httpClient.get(this.url + "employe/id/attendance/" + empId + "/");
  }
  // employee salary
  getEmployeesSalary() {
    return this.httpClient.get(this.url + "employe/salarylist/");
  }
  addEmployeeSalary(data) {
    return this.httpClient.post(this.url + "employe/salary/create/", data);
  }
  getEmployeeSalaryById(id: number) {
    return this.httpClient.get(this.url + "getemploye/salary/" + id + "/");
  }
  updateEmployeeSalary(data, id) {
    return this.httpClient.put(this.url + "employe/salary/update/" + id + "/", data);
  }
  deleteEmployeeSalary(id: number) {
    return this.httpClient.delete(this.url + "employe/salary/delete/" + id);
  }
  getEmployeeSalaryByEmpId(empId: number) {
    return this.httpClient.get(this.url + "employe/id/salary/" + empId + "/");
  }
  // expenditures
  getExpenditureList(){
    return this.httpClient.get(this.url + "expenditurelist/");
  }
  addExpenditure(data) {
    return this.httpClient.post(this.url + "expenditure/create/", data);
  }
  getExpenditureById(id: number) {
    return this.httpClient.get(this.url + "getexpenditure/" + id + "/");
  }
  updateExpenditure(data, id) {
    return this.httpClient.put(this.url + "expenditure/update/" + id + "/", data);
  }
  deleteExpenditure(id: number) {
    return this.httpClient.delete(this.url + "expenditure/delete/" + id);
  }
}
