import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../shared/api.service";
import { CommonService } from "../shared/common.service";
import * as html2pdf from "html2pdf.js";

@Component({
  selector: "app-download-invoice",
  templateUrl: "./download-invoice.component.html",
  styleUrls: ["./download-invoice.component.scss"],
})
export class DownloadInvoiceComponent {
  orderId: number;
  orderData: any;
  logo: string;
  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.orderId = parseInt(
      atob(this.activatedRoute.snapshot.params.encodedId),
      10
    );
    let orderData = new Promise((resolve, reject) => {
      this.apiService.getOrderById(this.orderId).subscribe((res: any) => {
        this.orderData = res;
        resolve(1);
      });
    });

    let settingData = new Promise((resolve, reject) => {
      this.apiService.getSettings().subscribe(
        (response: any) => {
          this.commonService.spin$.next(false);
          this.logo = response[0].logo;
          resolve(1);
        },
        (error: any) => {
          console.log(error);
        }
      );
    });
    Promise.all([orderData, settingData]).then((res) => {
      this.printPage();
    });
  }
  getTotalTax(cgst, sgst, igst) {
    return (parseFloat(cgst) + parseFloat(sgst) + parseFloat(igst)).toFixed(2);
  }
  printPage() {
    setTimeout(() => {
      window.print();
    }, 1000);  // Delaying the print to ensure the content is loaded
  }
}
