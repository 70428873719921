import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Inject, Injectable } from "@angular/core";
import { MatSpinner } from "@angular/material/progress-spinner";
import { jwtDecode } from "jwt-decode";
import { Subject } from "rxjs";
import { map, scan } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  private spinnerRef: OverlayRef = this.cdkSpinnerCreate();
  private spinnerTopRef = this.cdkSpinnerCreate();
  spin$: Subject<boolean> = new Subject();

  constructor(private overlay: Overlay) {
    this.spin$
      .asObservable()
      .pipe(
        map((val) => (val ? 1 : -1)),
        scan((acc, one) => (acc + one >= 0 ? acc + one : 0), 0)
      )
      .subscribe((res) => {
        if (res === 1) {
          this.showSpinner();
        } else if (res == 0) {
          this.spinnerTopRef.hasAttached() ? this.stopSpinner() : null;
        }
      });
  }
  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: "dark-backdrop",
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }

  private showSpinner() {
    try {
      this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
    } catch (e) {}
  }

  private stopSpinner() {
    try {
      this.spinnerTopRef.detach();
    } catch (e) {}
  }

  getUserName() {
    let refreshToken = sessionStorage.getItem("refreshToken");
    let accessToken = sessionStorage.getItem("accessToken");
    const decodedAccessToken = jwtDecode(accessToken);
    // console.log(decodedAccessToken, "decoded access token");
    /* {
      "token_type": "refresh",
      "exp": 1699450439,
      "iat": 1699364039,
      "jti": "9a8349698ea541358fc1cd086f7a47a2",
      "user_id": 1,
      "username": "admin",
      "user_type": "admin"
    } */
    return decodedAccessToken["username"] || "User";
  }
  getUserId() {
    let accessToken = sessionStorage.getItem("accessToken");
    const decodedAccessToken = jwtDecode(accessToken);
    return decodedAccessToken["user_id"] || "0";
  }
  getUserType() {
    let accessToken = sessionStorage.getItem("accessToken");
    const decodedAccessToken = jwtDecode(accessToken);
    return decodedAccessToken["user_type"] || "0";
  }
  isAdmin() {
    let accessToken = sessionStorage.getItem("accessToken");
    const decodedAccessToken = jwtDecode(accessToken);
    return decodedAccessToken["user_type"] == "admin" ? true : false;
  }
  getOutletId() {
    return sessionStorage.getItem("outlet_id") != "null"
      ? parseInt(sessionStorage.getItem("outlet_id"))
      : 0;
  }
  setOutletId(id) {
    sessionStorage.setItem("outlet_id", id);
  }
}
