<html>

<head>
    <style type="text/css" media="all">
        body {
            max-width: 300px;
            margin: 0 auto;
            text-align: center;
            color: #000;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
        }

        #wrapper {
            min-width: 125px;
            margin: 0px auto;
        }

        #wrapper img {
            max-width: 150px;
            width: auto;
        }

        h2,
        h3,
        p {
            margin: 5px 0;
        }

        .left {
            width: 100%;
            float: right;
            text-align: right;
            margin-bottom: 3px;
            margin-top: 3px;
        }

        .right {
            width: 40%;
            float: right;
            text-align: right;
            margin-bottom: 3px;
        }

        .table,
        .totals {
            width: 100%;
            margin: 10px 0;
        }

        .table th {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            padding-top: 4px;
            padding-bottom: 4px;
        }

        .table td {
            padding: 0;
        }

        .totals td {
            width: 24%;
            padding: 0;
        }

        .table td:nth-child(2) {
            overflow: hidden;
        }

        @media print {
            body {
                text-transform: uppercase;
            }

            #buttons {
                display: none;
            }

            #wrapper {
                width: 100%;
                margin: 0;
                font-size: 9px;
            }

            #wrapper img {
                max-width: 300px;
                width: 80%;
            }

            #bkpos_wrp {
                display: none;
            }
        }
    </style>
</head>

<body *ngIf="orderData && orderData.id && orderData.id > 0">
    <div id="wrapper">
        <span id="printContainer">
            <table border="0" style="border-collapse: collapse; width: 100%; height: auto;">
                <tr>
                    <td width="100%" align="center">
                        <img [src]="logo" style="width: 60px;" />
                    </td>
                </tr>
                <tr>
                    <td width="100%" align="center">
                        <h2 style="padding-top: 0px; font-size: 34px;"><strong>{{orderData.outlet_name}}</strong></h2>
                    </td>
                </tr>
                <tr>
                    <td width="100%">
                        <div class="left" style="text-align: left;"><b>Address : </b><span
                                [innerText]="orderData.outlet_address"></span></div>
                        <span class="left" style="text-align: left;"><b>Telephone :
                            </b>{{orderData.outlet_mobile}}</span>
                        <span class="left" style="text-align: left;"><b>Invoice No : </b>{{orderData.id}}</span>
                        <span class="left" style="text-align: left;"><b>Date :
                            </b>{{orderData.created_at | date:'dd/MM/yyyy h:mm a'}}</span>
                        <span class="left" style="text-align: left;"><b>Customer Name&nbsp; :</b>
                            {{orderData.customer_name}}</span>
                        <span class="left" style="text-align: left;"><b>Mobile :</b>
                            {{orderData.customer_mobile ? orderData.customer_mobile : '-'}}</span>
                    </td>
                </tr>
            </table>
            <div style="clear:both;"></div>
            <table class="table" cellspacing="0" border="0">
                <thead>
                    <tr>
                        <th width="10%"><em>#</em></th>
                        <th width="4%" align="left">Product Name</th>
                        <th width="4%" align="left">Product Code</th>
                        <th width="10%">Qty</th>
                        <th width="25%">Per Item</th>
                        <th width="20%" align="right">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of orderData.order_items;let i = index">
                        <td style="text-align:center; width:30px;" valign="top">{{i+1}}</td>
                        <td style="text-align:left; width:130px; padding-bottom: 20px" valign="top">
                            {{item.product_name}}
                        </td>
                        <td style="text-align:left; width:130px; padding-bottom: 20px" valign="top">
                            [{{item.product_code}}]
                        </td>
                        <td style="text-align:center; width:50px;" valign="top">{{item.qty}}</td>
                        <td style="text-align:center; width:50px;" valign="top">{{item.retail_price}}</td>
                        <td style="text-align:right; width:70px;" valign="top">{{item.retail_price * item.qty}}</td>
                    </tr>
                </tbody>
            </table>
            <table class="totals" cellspacing="0" border="0"
                style="margin-bottom:5px; border-top: 1px solid #000; border-collapse: collapse;">
                <tbody>
                    <tr>
                        <td style="text-align:left; padding-top: 5px;">Total Items </td>
                        <td
                            style="text-align:right; padding-right:1.5%; border-right: 1px solid #000;font-weight:bold;">
                            {{orderData.total_items}}</td>
                        <td style="text-align:left; padding-left:1.5%;">Sub Total</td>
                        <td style="text-align:right;font-weight:bold;">{{orderData.subtotal}}</td>
                    </tr>
                    <tr *ngIf="orderData.discount_total>0">
                        <td style="text-align:left;"></td>
                        <td
                            style="text-align:right; padding-right:1.5%; border-right: 1px solid #000;font-weight:bold;">
                        </td>
                        <td style="text-align:left; padding-left:1.5%; padding-bottom: 5px;">Discount
                            &nbsp;({{orderData.discount_percentage}} %)
                        </td>
                        <td style="text-align:right;font-weight:bold;">-
                            {{orderData.discount_total}}
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left; padding-top: 5px;">SGST ({{orderData.sgst_percent}}%) :</td>
                        <td
                            style="text-align:right; padding-right:1.5%; border-right: 1px solid #000;font-weight:bold;">
                            {{orderData.sgst_charge}}
                        </td>
                        <td style="text-align:left; padding-left:1.5%;">CGST ({{orderData.cgst_percent}}%) : </td>
                        <td style="text-align:right;font-weight:bold;">
                            {{orderData.cgst_charge}}
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left; padding-top: 5px;">IGST ({{orderData.igst_percent}}%) :</td>
                        <td
                            style="text-align:right; padding-right:1.5%; border-right: 1px solid #000;font-weight:bold;">
                            {{orderData.igst_charge}}
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style="text-align:left; padding-top: 5px;">&nbsp;</td>
                        <td
                            style="text-align:right; padding-right:1.5%; border-right: 1px solid #000;font-weight:bold;">
                            &nbsp;</td>
                        <td style="text-align:left; padding-left:1.5%;">Tax</td>
                        <td style="text-align:right;font-weight:bold;">
                            {{getTotalTax(orderData.cgst_charge,orderData.sgst_charge,orderData.igst_charge)}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"
                            style="text-align:left; font-weight:bold; border-top:1px solid #000; padding-top:5px;">
                            Grand Total</td>
                        <td colspan="2"
                            style="border-top:1px solid #000; padding-top:5px; text-align:right; font-weight:bold;">
                            {{orderData.grand_total}}
                        </td>
                    </tr>

                    <tr>
                        <td colspan="2" style="text-align:left; font-weight:bold; padding-top:5px;">
                            Paid Amount</td>
                        <td colspan="2" style="padding-top:5px; text-align:right; font-weight:bold;">
                            {{orderData.paid_amount}}</td>
                    </tr>
                    <tr *ngIf="orderData.return_change > 0">
                        <td colspan="2" style="text-align:left; font-weight:bold; padding-top:5px;">
                            Return Change</td>
                        <td colspan="2" style="padding-top:5px; text-align:right; font-weight:bold;">
                            {{orderData.return_change}}</td>
                    </tr>
                    <tr>
                        <td style="text-align:left; padding-top: 5px; font-weight: bold; border-top: 1px solid #000;">
                            Paid by : </td>
                        <td style="text-align:right; padding-top: 5px; padding-right:1.5%; border-top: 1px solid #000;font-weight:bold;"
                            colspan="3">
                            {{orderData.payment_method_name}}
                        </td>
                    </tr>
                    <tr *ngIf="orderData.payment_id == '6'">
                        <td style="text-align:left; padding-top: 5px; font-weight: bold;">
                            Cheque no : </td>
                        <td style="text-align:right; padding-top: 5px; padding-right:1.5%;font-weight:bold;"
                            colspan="3">
                            {{orderData.cheque_number}}
                        </td>
                    </tr>
                    <tr *ngIf="orderData.payment_id == '7'">
                        <td style="text-align:left; padding-top: 5px; font-weight: bold;">
                            Card no : </td>
                        <td style="text-align:right; padding-top: 5px; padding-right:1.5%;font-weight:bold;"
                            colspan="3">
                            {{orderData.card_number}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style="border-top:1px solid #000; padding-top:10px;">
                <span [innerHTML]="orderData.outlet_receipt_footer"></span>
            </div>
        </span>
    </div>
</body>

</html>