import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { AuthInterceptor } from "./auth.interceptor";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { OverlayModule } from "@angular/cdk/overlay";
import { ToasterContainerComponent } from "./shared/toaster-container.component";
import { ToasterComponent } from "./shared/toaster.component";
import { ToasterService } from "./shared/toaster.service";
import { NotFoundComponent } from "./not-found/not-found.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatButtonModule } from "@angular/material/button";
import { DatePipe, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { DownloadInvoiceComponent } from "./download-invoice/download-invoice.component";
@NgModule({
  declarations: [
    AppComponent,
    ToasterContainerComponent,
    ToasterComponent,
    NotFoundComponent,
    DownloadInvoiceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    OverlayModule,
    MatButtonModule,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
